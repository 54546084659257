import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
      <div className="container text-center vh-100 align-content-center">
        <h1>Oops! Page Not Found</h1>
        <p>Just emptiness.</p>
      </div>

    </Layout>
);

export default NotFoundPage;
